import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { defaultCountryCode, supportedCountryCodesSet } from '../config/countries'
import { defaultLanguageCode, supportedLanguageCodes } from '../config/languages'
import { getCountryWithLanguage } from "../services/get-country-with-language";
import { windowGlobal } from '../utils/window-global';

export function useLocatedPage(path = '') {
  useEffect(() => {
    const useCountryAndLanguageFromUrl = windowGlobal.location.href.includes('sp-signup') || windowGlobal.location.href.includes('workinksa')

    const { countryCode, languageCode } = getCountryWithLanguage(useCountryAndLanguageFromUrl)
    const finalCountryCode = supportedCountryCodesSet.has(countryCode) ? countryCode : defaultCountryCode
    const finalLanguageCode = supportedLanguageCodes.has(languageCode) ? languageCode : defaultLanguageCode
    navigate(`/${finalCountryCode}/${finalLanguageCode}/${path}${window.location.search}`)
  }, [])
}
