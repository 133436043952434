import { windowGlobal } from "../../utils/window-global";
import { defaultCountryCode, supportedCountryCodesSet } from "../../config/countries";
import { defaultLanguageCode, supportedLanguageCodes } from "../../config/languages";

export const getCountryWithLanguage = (fromUrl = false) => {
  if (fromUrl) {
    return { countryCode: getCountryCodeFromUrl(), languageCode: getLanguageCodeFromUrl() }
  }

  return { countryCode: getCountryCode(), languageCode: getLanguage() }
}

const getCountryCodeFromUrl = () => {
  const availableMarkets = Array.from(supportedCountryCodesSet).join('|')
  const regExp = new RegExp(`/(${availableMarkets})(/{0,1}.*)`)
  const marketMatch = windowGlobal.location.pathname.match(regExp)
  let code = localStorage.getItem('countryCode') ?? defaultCountryCode

  if (!!marketMatch) {
    code = marketMatch[1]
    localStorage.setItem('countryCode', code)
  }

  return code
}

export const getLanguageCodeFromUrl = () => {
  const availableMarkets = Array.from(supportedCountryCodesSet).join('|')
  const availableLanguages = Array.from(supportedLanguageCodes).join('|')

  const regExp = new RegExp(`/(${availableMarkets})/(${availableLanguages})(/{0,1}.*)`)
  const matches = windowGlobal.location.pathname.match(regExp)

  let language = localStorage.getItem('language') ?? defaultLanguageCode

  if (!!matches) {
    language = matches[2]
    localStorage.setItem('language', language)
  }

  return language
}

const getLanguage = () => {
  if (!windowGlobal) {
    return defaultLanguageCode
  }

  return localStorage.getItem('language') ?? defaultLanguageCode
}

const getCountryCode = () => {
  if (!windowGlobal) {
    return defaultCountryCode
  }

  return localStorage.getItem('countryCode') ?? defaultCountryCode
}
